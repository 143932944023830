export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const userStore = useUserStore()
  // Fetch user data to ensure the state is up-to-date
  await userStore.fetchUser()

  const user = userStore.user
  if (user) {
    if (user.role === 'admin') {
      return await navigateTo('/admin/dashboard')
    }
    else {
      return await navigateTo('/members/view')
    }
  }
})
